@use "@angular/material" as mat;
@use "sass:map";
@use "sass:color";

// Primary and accent palettes

$nvpanalyzer-colors-primary: (
  50: #e8f9fa,
  100: #c5f1f2,
  200: #9fe7ea,
  300: #79dde2,
  400: #5cd6db,
  500: #3fcfd5,
  550: #40cfd5,
  600: #39cad0,
  700: #31c3ca,
  800: #29bdc4,
  900: #1bb2ba,
  A100: #f0feff,
  A200: #bdfbff,
  A400: #8af8ff,
  A700: #70f7ff,
  contrast: (50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #000,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000)
);

$nvpanalyzer-colors-accent: (
  50: #f9ecf2,
  100: #f1cfde,
  200: #e7afc9,
  300: #dd8fb3,
  400: #d677a2,
  500: #cf5f92,
  600: #ca578a,
  700: #c34d7f,
  800: #bd4375,
  900: #b23263,
  A100: #fff9fb,
  A200: #ffc6db,
  A400: #ff93ba,
  A700: #ff7aaa,
  contrast: (50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000)
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$_nvpanalyzer-palette-primary: mat.define-palette($nvpanalyzer-colors-primary);
$_nvpanalyzer-palette-accent: mat.define-palette($nvpanalyzer-colors-accent);
$_nvpanalyzer-palette-warn: mat.define-palette(mat.$red-palette); // The warn palette is optional (defaults to red).

// Darker default hover color
//$mat-light-theme-background: map-merge($mat-light-theme-background, (hover: opacify(map-get($mat-light-theme-background, hover), 0.04)));

// Make adjustments to the default typography:
// - Font-size 14px instead of 16px
// - Table headers as 12px instead of 14px
// - Decrease letter spacing of buttons & tabs
$nvpanalyzer-typography: map.deep-merge(mat.define-typography-config(),
    (body-1: (font-size: 14px),
      body-2: (font-size: 14px),
      subtitle-1: (font-size: 14px),
      subtitle-2: (font-size: 12px),
      button: (letter-spacing: 0.75px)));

// Create the theme object (a Sass map containing all of the palettes).
$nvpanalyzer-theme: mat.define-light-theme((color: (primary: $_nvpanalyzer-palette-primary,
        accent: $_nvpanalyzer-palette-accent,
        warn: $_nvpanalyzer-palette-warn),
      typography: $nvpanalyzer-typography,
      density: -2));

$nvpanalyzer-palette-primary: map-get($nvpanalyzer-theme, primary);
$nvpanalyzer-palette-accent: map-get($nvpanalyzer-theme, accent);
$nvpanalyzer-palette-warn: map-get($nvpanalyzer-theme, warn);
$nvpanalyzer-palette-foreground: map-get($nvpanalyzer-theme, foreground);
$nvpanalyzer-palette-background: map-get($nvpanalyzer-theme, background);

// Colors that can be used directly from stylesheets

$theme-color-primary-lightest: mat.get-color-from-palette($nvpanalyzer-palette-primary, 50);
$theme-color-primary-lighter: mat.get-color-from-palette($nvpanalyzer-palette-primary, lighter); // 100
$theme-color-primary-light: mat.get-color-from-palette($nvpanalyzer-palette-primary, 300);
$theme-color-primary-default: mat.get-color-from-palette($nvpanalyzer-palette-primary, default); // 500
$theme-color-primary-darker: mat.get-color-from-palette($nvpanalyzer-palette-primary, darker); // 700
$theme-color-primary-darkest: mat.get-color-from-palette($nvpanalyzer-palette-primary, 900);

$theme-color-accent-lightest: mat.get-color-from-palette($nvpanalyzer-palette-accent, 50);
$theme-color-accent-lighter: mat.get-color-from-palette($nvpanalyzer-palette-accent, lighter); // 100
$theme-color-accent-default: mat.get-color-from-palette($nvpanalyzer-palette-accent, default); // 500
$theme-color-accent-darker: mat.get-color-from-palette($nvpanalyzer-palette-accent, darker); // 700
$theme-color-accent-darkest: mat.get-color-from-palette($nvpanalyzer-palette-accent, 900);

// For the default warning color, mix one of the accent colors with one of the warning colors
$theme-color-warn: mix(mat.get-color-from-palette($nvpanalyzer-palette-warn, text),
    mat.get-color-from-palette($nvpanalyzer-palette-accent, 200));
$theme-color-warn-orange: mix(yellow, $theme-color-warn);

$theme-color-success: #61ed9d;

$theme-color-text: mat.get-color-from-palette($nvpanalyzer-palette-foreground, text);
$theme-color-text-secondary: mat.get-color-from-palette($nvpanalyzer-palette-foreground, secondary-text);
$theme-color-text-hint: mat.get-color-from-palette($nvpanalyzer-palette-foreground, hint-text);
$theme-color-text-disabled: mat.get-color-from-palette($nvpanalyzer-palette-foreground, disabled-text);
$theme-color-text-disabled-button: mat.get-color-from-palette($nvpanalyzer-palette-foreground, disabled-button);
$theme-color-text-base: mat.get-color-from-palette($nvpanalyzer-palette-foreground, base);

$theme-color-divider: mat.get-color-from-palette($nvpanalyzer-palette-foreground, divider);
$theme-color-formfield-line: mat.get-color-from-palette($nvpanalyzer-palette-foreground, divider, 0.42);
$theme-color-hover: mat.get-color-from-palette($nvpanalyzer-palette-background, hover);

$theme-color-background: mat.get-color-from-palette($nvpanalyzer-palette-background, background);
$theme-color-background-card: mat.get-color-from-palette($nvpanalyzer-palette-background, card);
$theme-color-background-dialog: mat.get-color-from-palette($nvpanalyzer-palette-background, dialog);
$theme-color-background-toolbar: mat.get-color-from-palette($nvpanalyzer-palette-background, app-bar);
$theme-color-background-subtle: #fafafa;
$theme-color-background-grey: #F3F4F6;

$theme-color-button-selected: mat.get-color-from-palette($nvpanalyzer-palette-background, selected-button);
$theme-color-button-focused: mat.get-color-from-palette($nvpanalyzer-palette-background, selected-focused);
$theme-color-button-disabled: mat.get-color-from-palette($nvpanalyzer-palette-background, selected-disabled);

$theme-color-orange: #ff9736;
$theme-color-green: #93C54B;
$theme-color-purple: #5A427E;
$theme-color-blue: #0E518D;

$theme-color-origin: color.change($theme-color-orange, $alpha: 0.15);
$theme-color-destination: color.change($theme-color-green, $alpha: 0.15);
$theme-color-passthrough: color.change($theme-color-purple, $alpha: 0.15);
$theme-color-intersect: color.change($theme-color-blue, $alpha: 0.15);
