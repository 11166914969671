@use "vars.scss";

/**
 * General utilities and overrides
 */

// --------------------------------------------

/**
* Anchors default style
*/
a {
  color: vars.$theme-color-primary-darkest;
  text-decoration: none;
  font-weight: 500;
}

.anchor-external-icons a:not(.no-icon),
.anchor-external-icon.a:not(.no-icon) {
  &:after {
    font-family: "Material Icons";
    content: "\e89e";
    margin-left: 4px;
    vertical-align: -15%;
    font-size: 110%;
  }
}

/**
* Disable numeric input spinners in Firefox
*/
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/**
* Support filled icons in mat-icon for Material Symbols
*/
mat-icon.filled {
  font-variation-settings: 'FILL' 1;
}

/**
* Menu tweaks
*/
.mat-menu-panel {

  min-height: 0 !important;

  .mat-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .mat-menu-item:not(:last-child) {
      border-bottom: 1px solid #e8e7e8;
    }

    .mat-menu-item {
      display: flex;
      align-items: center;

      .mat-icon {
        font-size: 18px;
        color: #6a757c;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

.header-menu {
  &-right {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    margin-top: 8px;
  }
}

/**
* Drag and drop animations
*/

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// General scrollbar (not supported by Firefox)
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  background: #666666;
  border-radius: 10px;
  margin-right: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #444444;
}

// Scrollbar that is supported by firefox
.firefox {
  * {
    scrollbar-width: thin;
    scrollbar-color: #666666 #ffffff;
  }
}

/**
* Use `matTooltipClass="wide-tooltip"` on a MatTooltip to make it a bit wider than the default.
*/
.wide-tooltip .mdc-tooltip__surface {
  max-width: 275px;
}
